import { Heading1, Heading2, Paragraph } from '../components/Typography';
import React, { useCallback, useMemo } from 'react';
import { color, compose, space } from 'styled-system';

import Button from '../components/Button';
import Grid from '../components/Grid';
import HeroSection from '../components/HeroSection';
import PropTypes from 'prop-types';
import SEO from '../components/seo';
import Section from '../components/Section';
import UnstyledCard from '../components/Card';
import UnstyledLink from '../components/Link';
import defaultTheme from '../utils/themes';
import { navigate } from 'gatsby';
import propTypes from '@styled-system/prop-types';
import styled from 'styled-components';

// import HolidayOpeningHoursTable from './XmasTable';

const mapLink =
  'https://www.google.com/maps?ll=53.392575,-3.022165&z=13&t=m&hl=en-US&gl=GB&mapclient=embed&q=6+Europa+Blvd+Birkenhead+CH41+4PE';

const StyledLink = styled(UnstyledLink)`
  color: white;
  text-decoration: none;
  font-weight: 900;
  transition: 0.3s;

  :hover {
    text-decoration: underline;
    color: white;
  }
`;

const Link = styled(UnstyledLink).attrs({
  color: 'headerColor',
  fontSize: '18px',
  textDecoration: 'none',
})``;

const Card = styled(UnstyledCard).attrs({
  paddingTop: '30px',
  paddingBottom: '30px',
  paddingLeft: '30px',
  paddingRight: '30px',
})`
  > *:last-child {
    margin-bottom: 0;
  }
`;

const HighlightedContent = styled.div(compose(color, space));

HighlightedContent.propTypes = {
  ...propTypes.color,
  ...propTypes.space,
};

HighlightedContent.defaultProps = {
  backgroundColor: 'rgba(72, 78, 181, 0.06)',
  marginLeft: -4,
  marginRight: -4,
  marginTop: 0,
  marginBottom: 40,
  paddingLeft: 13,
  paddingRight: 13,
  paddingTop: 10,
  paddingBottom: 10,
};

const HighlightedText = styled(Paragraph).attrs({
  color: 'text-grey',
  fontWeight: 600,
  fontSize: '0.8rem',
  lineHeight: '1.2rem',
  marginBottom: '0px',
})``;

const ChristmasHours = styled.div`
  background-color: #e74c3c;
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 18px;
`;

const ChristmasHeading = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const ContactPage = () => {
  return (
    <>
      <SEO
        title='Contact'
        description={`
        If you want to contact Jacobs Enforcement, there are a number of ways you can get in touch.
        `}
      />
      <HeroSection paddingTop='8px'>
        <Heading1 marginTop='120px'>Contact us</Heading1>
        <Paragraph>
          Before you get in touch, check out our{' '}
          <StyledLink href='/faq/'>FAQ page</StyledLink> as you may find the
          answers you need.
        </Paragraph>
      </HeroSection>
      {/* <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <HolidayOpeningHoursTable />
      </Section> */}
      <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <Grid
          ieCardLayout
          gridTemplateRows={useMemo(
            () => ({
              xs: 'repeat(6, auto)',
              lg: 'repeat(3, 1fr)',
            }),
            []
          )}
          gridTemplateColumns={useMemo(
            () => ({
              xs: '1fr',
              lg: '1fr 1fr',
            }),
            []
          )}
          gridGap={useMemo(
            () => ({
              xs: '14px',
              lg: '28px',
            }),
            []
          )}
        >
          <Card>
            <Heading2>Phone</Heading2>
            <HighlightedContent>
              <HighlightedText>
                <Link href='tel:03456012692'>0345 601 2692 &nbsp;</Link>
                <Heading1 marginTop='16px' marginBottom='2px' fontSize='16px'>
                  HMCTS Court Fines and Warrants
                </Heading1>
                <Link
                  href='tel:03300563323 '
                  textDecoration='none'
                  color='headerColor'
                  fontSize='24px'
                >
                  0330 056 3323
                </Link>
                <Paragraph marginTop='24px'>
                  You will need your Jacobs reference to communicate with us.
                </Paragraph>
              </HighlightedText>
            </HighlightedContent>
            <Paragraph>
              Monday - Thursday: 08:00 - 19:00
              <br />
              Friday: 08:00 - 18:30
              <br />
              Saturday: 09:00 - 14:00
              <br />
              Sunday - Closed
            </Paragraph>
          </Card>

          <Card>
            <Heading2>Contact Form</Heading2>
            <Paragraph>
              Use your Jacobs reference, Client reference and postcode to access
              our contact form.
            </Paragraph>
            <Button
              textTransform='none'
              onClick={() => navigate('/contact-form')}
            >
              Access Contact Form
            </Button>
          </Card>

          <Card>
            <Heading2>WebChat</Heading2>
            <Paragraph>
              <strong>
                To access the Webchat, click the button located in the bottom
                right corner of the screen.
              </strong>
            </Paragraph>
            <Paragraph>
              Monday to Thursday 9am - 5.30pm
              <br />
              Friday 9am - 5pm
            </Paragraph>
          </Card>

          <Card>
            <Heading2>Post</Heading2>
            <Paragraph>Write to us at:-</Paragraph>
            <HighlightedContent>
              <HighlightedText>
                Jacobs
                <br />
                6 Europa Boulevard
                <br />
                Birkenhead
                <br />
                Merseyside
                <br />
                CH41 4PE
              </HighlightedText>
            </HighlightedContent>
          </Card>

          <Card>
            <Heading2>Visit our Office</Heading2>
            <div>
              <HighlightedContent>
                <HighlightedText>
                  <Link
                    href={mapLink}
                    style={{
                      fontSize: '18px',
                      fontWeight: 700,
                    }}
                  >
                    6 Europa Boulevard
                    <br />
                    Birkenhead
                    <br />
                    Merseyside
                    <br />
                    CH41 4PE
                  </Link>
                </HighlightedText>
              </HighlightedContent>
              <Paragraph>
                Monday to Thursday 9am - 5.30pm
                <br />
                Friday 9am - 5pm
              </Paragraph>
            </div>
          </Card>
        </Grid>
      </Section>
    </>
  );
};

ContactPage.propTypes = {
  theme: PropTypes.object,
};

ContactPage.defaultProps = {
  theme: defaultTheme,
};

export default ContactPage;
